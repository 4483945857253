@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap");
body,
html {
    overflow-x: hidden;
    margin: 0 auto;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    white-space: pre-line;
}

label {
    font-family: Manrope;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.57px;
    text-align: center;
    color: #fff;
}

@media (min-width: 768px) {
    .sty-logo .logo_alluxi {
        animation: animationLogoOne 0.5s ease;
        animation-fill-mode: forwards;
        max-width: 140px;
    }
    .sty-logo nav {
        animation: animationBackground 0.5s ease;
        animation-fill-mode: forwards;
    }
}

@keyframes animationLogoOne {
    0% {
        width: 207.4px;
        height: 70px;
    }
    100% {
        width: 192px;
        height: 64px;
    }
}

@keyframes animationBackground {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: white;
    }
}

.floating-effect {
    animation: floating 3s infinite ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 20px);
    }
    100% {
        transform: translate(0, 0);
    }
}

.blog_description h2,
.blog_description h3,
.blog_description h4,
.blog_description h5,
.blog_description h6 {
    color: #030448;
    line-height: 1.3;
    font-weight: 700;
    padding-top: 5px;
    margin: 0 0 30px;
    text-align: left;
}

.blog_description h2 {
    font-size: 2.5rem;
}

.blog_description h3 {
    font-size: 1.75rem;
}

.blog_description p {
    letter-spacing: .3px;
    line-height: 1.6;
    font-size: 1.25rem;
    margin-bottom: 30px;
}

.blog_description a {
    color: inherit;
    text-decoration: inherit;
    color: #1168c5;
    text-decoration: underline;
    transition: color .2s linear;
}

@media screen and (max-width: 767px) {
    .blog_description h2,
    .blog_description h3,
    .blog_description h4,
    .blog_description h5,
    .blog_description h6 {
        color: #030448;
        line-height: 1.3;
        font-weight: 700;
        padding-top: 5px;
        margin: 0 0 30px;
    }
    .blog_description h2 {
        font-size: 1.75rem;
    }
    .blog_description h3 {
        font-size: 1.375rem;
    }
    .blog_description p {
        letter-spacing: .2px;
        line-height: 1.5;
        font-size: 1.125rem;
        margin-bottom: 25px;
    }
}